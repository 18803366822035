import { Col, Row } from "react-bootstrap";
import { ButtonText, ServicesWithoutExpandibleSections } from "../../../utils/consts";
import { AccordionList, IconRight, Switcher } from "../../../components";
import { AccordionItem, Platform } from "../../../utils/models";
import { useEffect } from "react";

export const SizeNormal = ({ page }: { page: Platform }) => {

  useEffect(() => {
    if (page?.image) {
      const backgroundElement = document.querySelector(".template-services-background-img") as HTMLElement;
      if (backgroundElement) {
        backgroundElement.style.backgroundImage = `url(${page.image})`
      }
    }
  }, [page?.image]);

  return (
    <div>
      <Row className="template-services-background-img">        
        <Col className="template-services-header-section">
                         
            {page?.name && (
              <>
                <div className={"template-services-header"}>
                  <div className="template-services-graphic-line"></div>
                  <h1 style={{ marginBottom:"-7px" }}>{page.name.split(" ")[0]}</h1>
                </div>                  
                <h1>{page.name.split(" ").slice(1).join(" ")}</h1>
              </>
            )}
                      
            <p className="subheading">{page?.template.title}</p>
            <div className="btn-group">
              <a className="launch-button btn-primary-brown" rel="noreferrer" target="_blank" href={page?.link}>
                {ButtonText.Launch} {!page?.registerlink}
              </a>
              {page?.registerlink && (
                <a
                  className="launch-button btn-primary-brown"
                  rel="noreferrer"
                  target="_blank"
                  href={page?.registerlink}
                >
                  {ButtonText.Register}
                </a>
              )}
            </div>
        </Col>
      </Row>
      <Row className={"template services-wrapper"}>      
        <Col>        
          <Row className={"flex-column"}>
            {/* <Col className="about-page">
              <h3>
                <span className={"title"}>{ 'About ' + page?.name}</span>
              </h3>
            </Col> */}
            <Col className="description">
              {page?.template.description.split("\n").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </Col>
          </Row>
          <div className="graphic-line-service-wrapper service-page"></div>
        <Row>
          <Col className="list">
            {!ServicesWithoutExpandibleSections.includes(page?.name.toLocaleLowerCase()) && (
              <AccordionList data={page?.template.accordion as AccordionItem[]} />
            )}
          </Col>
        </Row>
        </Col>
        <Col xs={1} />
        <Col style={{ position: "relative" }}>
          {Array.isArray(page?.template.images) && (page?.template?.images?.length || 0) > 0 && (
            <Switcher useFixed={true} images={page?.template.images as string[]} />
          )}
        </Col>
      </Row>
    </div>
    
  );
};
